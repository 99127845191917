import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLearnerContext } from '../../../context/LearnerContext';
import TypographyUI from '../../../components/TypographyUI';
import {
  contentWithFormString,
  trainingTypes,
} from '../../../constants/trainings';
import { learnerRegisteredFrom } from '../../../constants/learners';
import { extractTextAndLink } from '../../../helpers/trainings';
import { convertDateFormat } from '../../../helpers/dates';
import { diffuserTypes } from '../../../constants/broadcast';
import { formatPhoneNumberWithPrefix } from '../../../helpers';

const EvaluateDetails = () => {
  const [selectedBroadcast, setSelectedBroadcast] = useState(null);
  console.log('selectedBroadcast', selectedBroadcast);

  const { exportStatistics, getDetailedStatistics } = useLearnerContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const getStats = async (id) => {
    const stats = await getDetailedStatistics(id)

    setSelectedBroadcast(stats?.[0] || null)
  }

  useEffect(() => {
    if (id) {
      getStats(id)
    }
  }, [id]);

  const handleExport = async () => {
    await exportStatistics(selectedBroadcast._id);
  };
  const navigateToStatistics = (training) => {
    navigate(
      `/evaluate-statistics?training_id=${training._id}&broadcast_id=${selectedBroadcast._id}`
    );
  };

  if (!selectedBroadcast) return <></>;
  return (
    <div style={{ textAlign: 'left', fontFamily: 'Montserrat' }}>
      <Box sx={{ display: 'inline-block', marginBottom: '20px' }}>
        <font
          size="4"
          style={{
            cursor: 'pointer',
            marginRight: '5px',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
          }}
          onClick={() => navigate('/evaluate')}
        >
          <div className="fa fa- fa-chevron-circle-left"></div>
          <TypographyUI
            variant="h6"
            color="black"
            fontWeight={500}
            fontSize="16px"
          >
            Retour à la liste des évaluations de campagnes
          </TypographyUI>
        </font>
      </Box>
      {selectedBroadcast && (
        <div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              gap: 1,
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginBottom: '30px',
                color: 'blue',
                fontFamily: 'Montserrat',
              }}
            >
              <strong>{selectedBroadcast.name}</strong>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              sx={{ flexShrink: 0 }}
            >
              Exporter
            </Button>
          </Box>
          <Typography sx={{ marginBottom: 5 }}>
            {selectedBroadcast.description}
          </Typography>
          <Typography>
            Date de début:{' '}
            <strong>
              {(selectedBroadcast?.startDate &&
                convertDateFormat(selectedBroadcast.startDate)) ||
                (selectedBroadcast?.first_training_date &&
                  convertDateFormat(selectedBroadcast?.first_training_date))}
            </strong>
          </Typography>
          <Typography>
            Nb. de contenus:{' '}
            <strong>
              {selectedBroadcast.days || selectedBroadcast?.trainings.length}
            </strong>
          </Typography>
          <Typography>
            Titre des micro-formations:{' '}
            {selectedBroadcast?.trainings.map((training) => (
              <strong key={training.id}>{training.name}, </strong>
            ))}
          </Typography>

          {!selectedBroadcast?.training_engagement ||
          selectedBroadcast?.training_engagement.length === 0 ? (
            <Box sx={{ marginTop: '50px' }}>
              <TypographyUI
                variant="h6"
                color="black"
                fontWeight={500}
                fontSize="16px"
              >
                Il n'y a pas de réponses
              </TypographyUI>
            </Box>
          ) : (
            <>
              <Typography style={{ marginTop: '50px' }}>
                Nombre total d'apprenants:
                <strong style={{ marginLeft: '5px' }}>
                  {diffuserTypes.timing.value === selectedBroadcast.type
                    ? selectedBroadcast?.recipients
                    : selectedBroadcast?.recipients_amount ||
                      selectedBroadcast?.recipients}
                </strong>
              </Typography>
              <Typography>
                Nb. d'inscrits via le formulaire d'invitation:{' '}
                <strong>
                  {selectedBroadcast?.subscribers ||
                    selectedBroadcast?.subscribers}
                </strong>
              </Typography>
              <Typography>
                Nb. d'inscrits via la base de donnée:{' '}
                <strong> {selectedBroadcast?.database_learners}</strong>
              </Typography>
              <Typography>
                Nb. de désinscrits:{' '}
                <strong>{selectedBroadcast?.unsubscribed}</strong>
              </Typography>
            </>
          )}
        </div>
      )}

      {selectedBroadcast?.training_engagement && (
        <TableContainer
          component={''}
          style={{ border: '1px solid #c2e3d1', marginTop: '20px' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="green-border">
                  Titre de la micro-formation
                </TableCell>
                <TableCell className="green-border">
                  Taux d'engagement
                </TableCell>
                <TableCell className="green-border">
                  Taux de clics
                </TableCell>
                <TableCell className="green-border">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBroadcast.training_engagement &&
                selectedBroadcast?.training_engagement.map((training) => {
                  const hasPayload = training.hasPayload;
                  let trainingType = '';

                  if (training?.content.includes('/g/'))
                    trainingType = trainingTypes.withForm;
                  else if (
                    training?.responseType &&
                    training?.responseType !== 'Non'
                  ) {
                    trainingType = trainingTypes.withQuestion;
                    
                  } else if (
                    training?.content.includes('/w/')
                  ) {
                    trainingType = trainingTypes.withWebAnswer;
                  }

                  const content = extractTextAndLink(training.content);
                  const tooltipText = content?.text || training.content;

                  return (
                    <TableRow
                      className="green-border"
                      key={training.name + training.date}
                    >
                      <TableCell className="green-border">
                        <span>{training.name}</span>
                        <Tooltip
                          title={
                            <div
                              style={{
                                maxWidth: '500px',
                                overflowY: 'auto',
                              }}
                            >
                              <h3 style={{ color: '#66deae' }}>Content:</h3>

                              <span
                                style={{
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {' '}
                                {tooltipText}
                              </span>
                            </div>
                          }
                        >
                          <Button
                            size="small"
                            startIcon={<i className="fas fa-eye"></i>}
                            style={{ margin: '0', padding: '0' }}
                          ></Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="green-border">
                        {training.engagement || '0.00%'}
                      </TableCell>
                      <TableCell className="green-border">
                        {training.taxClicks || '0.00%'}
                      </TableCell>
                      <TableCell className="green-border">
                        <span
                          style={{
                            cursor:
                              trainingType && hasPayload
                                ? 'pointer'
                                : 'default',
                            textDecoration:
                              trainingType && hasPayload ? 'underline' : 'none',
                          }}
                          onClick={() => {
                            if (trainingType && hasPayload) {
                              navigateToStatistics(training);
                            }
                          }}
                        >
                          {trainingType && hasPayload ? 'Voir réponses' : null}
                          {trainingType && !hasPayload
                            ? 'Pas de réponse'
                            : null}
                          {trainingType && hasPayload ? '' : null}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedBroadcast?.learners_data && (
        <TableContainer
          component={''}
          style={{ border: '1px solid #c2e3d1', marginTop: '20px' }}
        >
          <Table>
            <TableHead>
              <TableRow className="green-border">
                <TableCell>Prénom</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>
                  Téléphone
                  <br /> 
                  <small style={{ whiteSpace: 'nowrap'}}>(ex: 33 6 85 53 99 59)</small>
                </TableCell>
                <TableCell>Méthode d'enregistrement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBroadcast.learners_data.map((learner, key) => {
                const registrationType = learner?.native
                  ? learnerRegisteredFrom.native.label
                  : learner?.registration
                  ? learnerRegisteredFrom.registration.label
                  : learnerRegisteredFrom.unknown.label;

                return (
                  <TableRow key={key} className="green-border">
                    <TableCell className="green-border">
                      {learner.firstName}
                    </TableCell>
                    <TableCell className="green-border">
                      {learner.lastName}
                    </TableCell>
                    <TableCell className="green-border">
                      {formatPhoneNumberWithPrefix(learner.phoneNumber)}
                    </TableCell>
                    <TableCell className="green-border">
                      {registrationType}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default EvaluateDetails;
