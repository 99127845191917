import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLearnerContext } from '../../../context/LearnerContext';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import DialogTitleCustom from '../../../components/DialogTitleCustom';
import useSortableTable from '../../../hooks/useSortTable';
import { formatPhoneNumberWithPrefix } from '../../../helpers';

function SelectRecipient({ disabled }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLearners, setFilteredLearnsers] = useState([]);

  const { availableLearners, getAvailableLearners, setSelectedLearnerNames } =
    useLearnerContext();

  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredLearners,
    {
      key: 'firstName',
      direction: 'asc',
    }
  );

  useEffect(() => {
    const searchTermLowerCase = searchQuery.toLowerCase();

    const filteredTable = availableLearners.filter((learner) => {
      const firstNameIncludesSearchTerm = learner.firstName
        .toLowerCase()
        .includes(searchTermLowerCase);

      const lastNameIncludesSearchTerm = learner.lastName
        .toLowerCase()
        .includes(searchTermLowerCase);

      const isTagsIncludesSearchTerm = learner.tags.some((tag) =>
        tag.toLowerCase().includes(searchTermLowerCase)
      );

      const isPhonecludesSearchTerm = learner.phoneNumber
        .toLowerCase()
        .includes(searchTermLowerCase);

      return (
        firstNameIncludesSearchTerm ||
        lastNameIncludesSearchTerm ||
        isTagsIncludesSearchTerm ||
        isPhonecludesSearchTerm
      );
    });
    setFilteredLearnsers(filteredTable);
  }, [availableLearners, searchQuery]);

  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedLearners, setSelectedLearners] = useState([]);

  const handleConfirm = async () => {
    const learnerNames = availableLearners
      .filter((learner) => selectedLearners.includes(learner._id))
      .map((learner) => learner._id);

    setSelectedLearnerNames(learnerNames ? learnerNames : []); // Update the global state

    setSelectedCount(learnerNames.length);
    handleClose();
  };

  const handleSelectionChange = (learnerId, isSelected) => {
    if (isSelected) {
      setSelectedLearners((prevSelected) => [...prevSelected, learnerId]);
      setSelectedCount((prevCount) => prevCount + 1);
    } else {
      setSelectedLearners((prevSelected) =>
        prevSelected.filter((id) => id !== learnerId)
      );
      setSelectedCount((prevCount) => prevCount - 1);
    }
  };

  const selectAllLearners = () => {
    const allSelectedLearners = filteredLearners.map((learner) => learner._id);
    const uniqueLearners = [
      ...new Set([...allSelectedLearners, ...selectedLearners]),
    ];

    setSelectedLearners(uniqueLearners);
    setSelectedCount(uniqueLearners.length);
  };

  useEffect(() => {
    getAvailableLearners();
  }, []);
  return (
    <>
      <Button
        sx={{ paddingLeft: 0, color: '#4546CD' }}
        onClick={handleOpen}
        disabled={disabled}
      >
        <Typography variant="h7">Sélectionner les destinataires</Typography>
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        scroll={'paper'}
        aria-labelledby="select-recipient"
        aria-describedby="dialog-select-recipient"
      >
        <DialogTitleCustom id="select-recipient-title" fontSize="20px">
          Sélectionner les destinataires
        </DialogTitleCustom>
        <DialogContent>
          <Typography variant="body2">
            Nombre de destinataire(s) du parcours : {selectedCount}
          </Typography>

          <Box sx={{ display: 'flex' }}>
            <TextField
              label="Recherche"
              autoFocus
              autoComplete="off"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Button
              sx={{ flexShrink: 0, color: '#4546CD' }}
              onClick={selectAllLearners}
            >
              Tout sélectionner
            </Button>
          </Box>
          {sortedData.length ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sélection</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'lastName'}
                        direction={
                          sortConfig.key === 'lastName'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('lastName')}
                      >
                        Nom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <TableSortLabel
                        active={sortConfig.key === 'firstName'}
                        direction={
                          sortConfig.key === 'firstName'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('firstName')}
                      >
                        Prénom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Tags</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((learner) => (
                    <TableRow key={learner._id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedLearners.includes(learner._id)}
                          onChange={(e) =>
                            handleSelectionChange(learner._id, e.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell>{learner.lastName}</TableCell>
                      <TableCell>{learner.firstName}</TableCell>
                      <TableCell>{formatPhoneNumberWithPrefix(learner.phoneNumber)}</TableCell>

                      <TableCell>{learner.tags.join(', ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box>
              <Typography
                variant="body1"
                style={{ padding: '16px 16px 0 16px', textAlign: 'center' }}
              >
                Aucun apprenant enregistré.
              </Typography>
              <Typography
                variant="body1"
                style={{ padding: '0 16px 16px 16px', textAlign: 'center' }}
              >
                Veuillez en ajouter un nouveau.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            Annuler
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectRecipient;
