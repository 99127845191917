import moment from 'moment';

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const removeSpaces = (phoneNumber) => {
  return phoneNumber.replace(/\s/g, '');
};

export const formatUTCDate = (date) => {
  const utc = moment.utc(date);
  const day = String(utc.date()).padStart(2, '0');
  const month = String(utc.month() + 1).padStart(2, '0');
  return `${day}/${month}/${utc.year()}`
}

export const formatDates = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const changeColorsForDev = () => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    document.documentElement.style.setProperty('--bg-main', 'rgb(238 232 248)');
  }
};

export const setPlaceholderForInput = (params) => {
  const timeout = params?.timeout || 0;
  const prefix = params?.prefix || false;

  const frMask = '6 00 00 00 00';
  const frPrefixMask = '+33 6 00 00 00 00';

  setTimeout(() => {
    const phoneInput = document.querySelector(
      '.react-international-phone-input-container input'
    );

    if (phoneInput) {
      phoneInput.setAttribute('placeholder', prefix ? frPrefixMask : frMask);
    }
  }, timeout);
};

export const getCompanyIndex = (id, orgs = null) => {
  if (!orgs) return null;
  const selectedOrg = orgs.findIndex((org) => org._id === id);

  return selectedOrg;
};

export const formatPhoneNumberWithPrefix = (phoneNumber) => {
    try {
      // Ensure the number starts with "+" before parsing
      const globalNumber = `+${phoneNumber}`;

      // Parse the phone number
      const parsedNumber = phoneUtil.parseAndKeepRawInput(globalNumber, null);

      // Validate the number
      if (!phoneUtil.isValidNumber(parsedNumber)) {
          // If invalid, return the input unchanged
          return phoneNumber;
      }

      // Format the number in the desired format (INTERNATIONAL) and remove the "+" symbol
      const formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      return formattedNumber.startsWith("+") ? formattedNumber.slice(1) : formattedNumber;
  } catch (error) {
      // In case of any error, return the input unchanged
      return phoneNumber;
  }
}
