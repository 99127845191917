import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import './AdminPanel.scss';
import React, { useEffect, useState } from 'react';
import { actionTypes } from '../../constants/types';
import { UsersService } from '../../services/users';
import CreateEditUser from './CreateEditUser';
import TypographyUI from '../../components/TypographyUI';
import DeleteUser from './components/DeleteUser';
import useStore from '../../store/store';
import Organisation from '../../components/Organization/Organisation';
import { isAdmin } from '../../helpers/user';
import { useLearnerContext } from '../../context/LearnerContext';
import useSortableTable from '../../hooks/useSortTable';
import { formatPhoneNumberWithPrefix } from '../../helpers';

const AdminPanel = () => {
  const { userEmail: currentUserEmail, setSnackbarContent } = useStore(
    (store) => store
  );
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoaderVisible, setLoaderVisible] = useState(true);

  const role = useStore((state) => state.userRole);
  const isUserAdmin = isAdmin(role);
  const { organization } = useLearnerContext();
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getAllUsers = async () => {
    const response = await UsersService.getUsers();
    setUsers(response.data.data);
    setLoaderVisible(false);
  };

  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredUsers,
    {
      key: 'firstName',
      direction: 'asc',
    }
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (users.length) {
      const searchTermLowerCase = searchTerm.toLowerCase();

      const filteredTable = users.filter((user) => {
        const labels = ['email', 'lastName', 'firstName', 'phoneNumber'];

        return labels.some((label) => {
          if (user[label]) {
            return user[label].toLowerCase().includes(searchTermLowerCase);
          }
        });
      });

      setFilteredUsers(filteredTable);
    }
  }, [searchTerm, users]);

  if (isLoaderVisible) return <></>;

  return (
    <Box>
      <TypographyUI
        variant="h4"
        fontSize="32px"
        align="left"
        marginBottom="10px"
      >
        Panneau d'administration
      </TypographyUI>

      <TypographyUI
        variant="h5"
        fontSize="24px"
        align="left"
        marginBottom="10px"
      >
        Bénéficiaire
      </TypographyUI>

      <Box className="admin-panel-header">
        <TextField
          label="Recherche"
          autoComplete="off"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ marginRight: '10px' }}
        />
        <CreateEditUser type={actionTypes.create} refreshUsers={getAllUsers} />
      </Box>

      <>
        {filteredUsers.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="green-border">
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'lastName'}
                      direction={
                        sortConfig.key === 'lastName'
                          ? sortConfig.direction
                          : 'asc'
                      }
                      onClick={() => handleSort('lastName')}
                    >
                      Prénom
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'firstName'}
                      direction={
                        sortConfig.key === 'firstName'
                          ? sortConfig.direction
                          : 'asc'
                      }
                      onClick={() => handleSort('firstName')}
                    >
                      Nom
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'company_id'}
                      direction={
                        sortConfig.key === 'company_id'
                          ? sortConfig.direction
                          : 'asc'
                      }
                      onClick={() => handleSort('company_id')}
                    >
                      Organisation
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'email'}
                      direction={
                        sortConfig.key === 'email'
                          ? sortConfig.direction
                          : 'asc'
                      }
                      onClick={() => handleSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                  Téléphone
                  <br /> 
                  <small style={{ whiteSpace: 'nowrap'}}>(ex: 33 6 85 53 99 59)</small>
                </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'role'}
                      direction={
                        sortConfig.key === 'role' ? sortConfig.direction : 'asc'
                      }
                      onClick={() => handleSort('role')}
                    >
                      Rôle
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row) => (
                  <TableRow className="green-border" key={row._id}>
                    <TableCell className="green-border">
                      {row.firstName}
                    </TableCell>
                    <TableCell className="green-border">
                      {row.lastName}
                    </TableCell>

                    <TableCell className="green-border">
                      {organization.find(
                        (company) => company._id === row.company_id
                      )?.name || row.company_id}
                    </TableCell>
                    <TableCell className="green-border">{row.email}</TableCell>
                    <TableCell className="green-border">
                      {formatPhoneNumberWithPrefix(row.phoneNumber)}
                    </TableCell>
                    <TableCell className="green-border">{row.role}</TableCell>
                    <TableCell className="green-border">
                      <Box className="align-cell-center">
                        <CreateEditUser
                          type={actionTypes.edit}
                          id={row._id}
                          refreshUsers={getAllUsers}
                        />
                        <DeleteUser
                          currentUserEmail={currentUserEmail}
                          user={row}
                          setSnackbarContent={setSnackbarContent}
                          refreshData={getAllUsers}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        {!users.length ? (
          <Typography variant="body1" sx={{ padding: '16px' }}>
            il n'y a aucun utilisateur
          </Typography>
        ) : null}

        {users.length && !filteredUsers.length ? (
          <Typography variant="body1" sx={{ padding: '16px' }}>
            il n'y a pas d'utilisateurs spécifiques
          </Typography>
        ) : null}
      </>

      <div style={{ marginTop: '20px' }}>{isUserAdmin && <Organisation />}</div>
    </Box>
  );
};

export default AdminPanel;
